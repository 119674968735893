.brand-lincoln {
    .notification-item-wrapper {
        display: flex;
        @include mobile {
            flex-direction: column;
        }

        .icon-item {
            padding-right: 20px;
            padding-top: 7px;
            text-align: center;

            img {
                height: 19px;
                width: 22px;
            }

            @include mobile {
                padding-right: 0;
            }
        }

        .details-item {
            flex-grow: 2;
            text-align: left;
            @include mobile {
                text-align: center;
            }
        }

        .details-message {
            font-family: $proxima-nova-bold;
            font-size: pToR(24);
            font-weight: bold;
            letter-spacing: pToR(3);
        }

        .details-subtext {
            color: $fds-color-dk-gray;
            font-family: $proxima-nova-regular;
            font-size: pToR(14);
            letter-spacing: pToR(1);
            line-height: pToR(26);
        }

        .button-item {
            margin: auto;
            @include mobile {
                margin-top: 24px;
            }
        }
    }
}
