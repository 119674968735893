.brand-lincoln {
    .ymm-vin-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 450px;
        min-width: 320px;
        position: relative;
        @include desktop {
            align-items: flex-start;
            &:nth-of-type(2) {
                margin-left: 68px;
            }
        }
        .ymm-vin-header {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 0 48px 0;
            @media screen and (min-width: $ipad-landscape-width) {
                align-self: start;
            }
            .ymm-vin-tooltip {
                position: relative;
            }
            .selector-label {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                text-transform: uppercase;
                font-size: 18px;
                letter-spacing: 3px;
                line-height: 1.875rem;
                font-weight: 300;
                margin: 0;
                text-align: center;
            }
        }
        .ymm-vin-body {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            &.d-flex {
                flex-direction: row;
            }
            .vin-input-container {
                .badCharModal {
                    .modal-content {
                        max-width: 600px;
                        .modal-header {
                            margin-bottom: 0px;
                            height: 66px;
                            .modal-close {
                                height: 15px;
                                width: 15px;
                            }
                        }
                        .modal-body {
                            font-family: $proxima-nova-regular;
                            font-size: 20px;
                            text-align: center;
                            padding: 0px 30px 0px 30px;
                            margin: 0px;
                            .modal-title {
                                font-family: $proxima-nova-semi-bold;
                                font-size: 32px;
                                margin-bottom: 20px;
                            }
                        }
                        .modal-footer {
                            background-color: $white;
                            padding: 50px 16px 50px 16px;
                            margin: 0 auto;
                            display: flex;
                            .modal-footer-buttons {
                                display: flex;
                                flex-direction: row;
                                .primary-button {
                                    padding: 0 15px 0 20px;
                                    .button-label {
                                        margin-right: 8px;
                                    }
                                }
                                .text-button {
                                    margin-left: 24px;
                                    border: 0 transparent;
                                    color: $brand-secondary;
                                }
                            }
                            .link-button {
                                padding-left: 32px;
                                line-height: 13px;
                                font-size: 18px;
                                letter-spacing: 1px;
                                font-family: $proxima-nova-regular;
                            }
                        }
                    }
                }
                text-align: left;
                .label {
                    bottom: 120px;
                    line-height: pToR(12);
                    font-family: $proxima-nova-regular;
                    color: $fds-color-dk-gray;
                    font-size: pToR(12);
                    letter-spacing: 1px;
                    padding-bottom: 12px;
                    text-align: left;
                    @include tablet-portrait {
                        bottom: 115px;
                    }
                    .icons-text-field-icons-warning {
                        padding-left: 5px;
                    }
                }
                .vin-input {
                    @include input-border();
                    @include input-font();
                    padding: 0 12px;
                    color: $dark-blue;
                    width: 100%;
                    height: 50px;
                    display: inline-block;
                    margin: 0 auto;
                    border-radius: 3px;
                    box-sizing: border-box;
                    border: none;
                    border-bottom: 2px solid $fds-color-black;
                    background: #ecedee;
                    &:focus {
                        box-shadow: $outer-drop-shadow;
                        outline: none;
                        border-bottom-color: $brand-border;
                    }
                    @include tablet {
                        padding: 0 16px;
                    }
                    @include desktop {
                        min-width: 450px;
                    }
                    &.error {
                        border: 1px solid $severe;
                    }
                }
            }
            .vehicle-selector-button {
                margin: 24px auto 0;
                @include md-desktop {
                    margin: 32px 0 0 0;
                    width: fit-content;
                }
                &.d-flex {
                    margin: 20px 24px 0;
                    height: 60px;
                    align-self: center;
                }
                &.d-flex-error {
                    margin: 0px 24px;
                    height: 60px;
                    align-self: center;
                }
            }
        }
    }
    .find-vin-cta {
        padding-top: 18px;
        a {
            margin-top: 18px;
            letter-spacing: 1px;
            font-size: 12px;
            color: #4d4d4d;
        }
    }
}
