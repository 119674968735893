@import '../../../styles/main.scss';
.brand-ford {
    .vehicle-checklist {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
            0 10px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: $fds-color-white;
        justify-content: space-between;

        .vehicle-checklist-text-container {
            align-content: center;
            padding-left: 24px;
            .vehicle-checklist-heading {
                width: 100%;
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 20px;
                line-height: 29px;
                padding-top: 24px;
                display: flex;
                justify-content: space-between;
                text-transform: uppercase;
            }
            .vehicle-checklist-icon {
                height: 40px;
                width: 40px;
                margin-right: 24px;
            }

            .vehicle-checklist-description {
                color: $fds-color-dk-gray;
                font-family: $f1-font-light;
                font-size: 14px;
                line-height: 21px;
                width: 267px;
            }
        }
        .secondary-button {
            .vehicle-checklist-cta {
                padding-left: 24px;
                padding-bottom: 12px;
                justify-content: flex-start;
            }
        }
    }
    .vehicle-checklist-preimagine {
        box-shadow: none;
        border-radius: 8px;
        border: 1px solid $border-neutral;
        width: 100%;
        height: 100%;
        .vehicle-checklist-preimagine-text-container {
            .vehicle-checklist-preimagine-heading {
                width: 100%;
                color: $f1-text-blue;
                font-family: $f1-font-semibold;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                .vehicle-checklist-preimagine-header {
                    align-self: flex-start;
                    padding: 20px 0;
                }
                .vehicle-checklist-preimagine-icon {
                    margin: 16px 16px 0 16px;
                    height: 32px;
                    width: 32px;
                }
            }
            .vehicle-checklist-preimagine-description {
                color: $f1-text-blue;
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 24px;
                padding: 0 16px 0 64px;
                width: 80%;
                @include tablet {
                    width: 85%;
                }
                @include desktop {
                    width: fit-content;
                }
            }
        }
        .fm-btn {
            margin-left: 40px;
            margin-bottom: 5px;
            justify-content: flex-start;
            font-family: $f1-font-medium;
            &:focus {
                outline: revert;
            }
        }
    }
}
