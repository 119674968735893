.brand-lincoln {
    .feature-content-b {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: $proxima-nova-regular;
        color: $white;
        margin: 60px auto;
        background-size: cover;
        background-position: center center;
        max-height: 500px;

        @include desktop {
            margin: 100px auto;
            color: $white;
        }

        .feature-content-b-overlay {
            position: absolute;
            background: rgba(0, 0, 0, 0.3);
        }

        .feature-content-b-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 80px 40px;
            max-width: 780px;

            @include desktop {
                padding-top: 160px;
                padding-bottom: 160px;
            }

            .feature-content-b-title {
                font-family: $proxima-nova-light;
                font-size: pToR(48);
                line-height: pToR(58);
                letter-spacing: 3px;
                margin-bottom: 0;
                color: white;
                text-align: center;
                vertical-align: middle;
                text-transform: uppercase;
            }

            .feature-content-b-subtitle {
                display: none;
                @include desktop {
                    font-family: $proxima-nova-regular;
                    margin-top: 16px;
                    font-size: 1rem;
                    letter-spacing: 1px;
                    line-height: pToR(26);
                    text-transform: uppercase;
                    display: block;
                }
            }

            .feature-content-b-description {
                font-family: $proxima-nova-regular;
                text-align: center;
                vertical-align: middle;
                font-size: 1rem;
                margin-bottom: 30px;
                margin-top: 16px;

                @include desktop {
                    text-align: center;
                    vertical-align: middle;
                }
            }

            .secondary-button-link.feature-content-b-link {
                margin-top: 24px;
                color: white;
            }
        }
    }
}
