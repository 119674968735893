@import '../../../../styles/main.scss';
.brand-ford {
    .mmota-modal {
        .modal-body {
            max-width: pToR(600);
            margin: 0 auto;
            color: $text-gray;
            font-family: $f1-font-regular;
            line-height: pToR(26);
            text-align: center;
        }
        .mmota-modal-header,
        .mmota-updates__header {
            font-size: pToR(24);
            font-family: $f1-font-light;
        }

        .mmota-list-body {
            list-style: none;
            font-family: $f1-font-regular;
            text-align: center;
            padding: 0 1rem;

            @include desktop {
                padding: 0 2rem;
            }
            .mmota-list-item {
                padding: 1rem;
            }
        }
        .secondary {
            padding-top: 2rem;
        }

        hr {
            box-shadow: none;
            margin: pToR(40) auto;
        }

        .mmota-updates__header {
            margin-top: pToR(80);
        }

        .mmota-modal-link {
            color: $dark-blue;
            display: block;
            text-decoration: none;
            margin-top: pToR(25);

            @include xl-desktop {
                &.inline {
                    display: inline-block;
                }

                &.first {
                    float: left;
                }

                &.last {
                    float: right;
                }
            }
        }

        .top-padding {
            margin-top: pToR(25);
        }

        .mmota-updates__header-hr {
            margin: 0 auto 1rem;
        }

        .mmota-updates__notes {
            white-space: pre-line;
            text-align: left;
        }
    }
    .fm-modal.mmota-modal {
        .fm-modal-header {
            .fmc-dialog__close {
                font-size: 16px;
            }
        }
        .fm-modal-subtitle {
            color: $text-blue;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 24px;
            padding: 16px;
            text-align: left;
            a {
                color: $fm-skyview;
                font-family: $f1-font-bold;
                font-size: 14px;
                line-height: 16px;
                &:hover {
                    color: $fm-denim-blue;
                }
            }
        }
        .fm-mmota-button-wrapper {
            display: flex;
            align-self: center;
            flex-direction: column;
            @include mobile {
                align-self: flex-start;
            }
            .fm-btn {
                height: fit-content;
                align-self: flex-start;
            }
        }
        hr {
            box-shadow: none;
            margin: 16px auto;
        }
        .fm-modal-slot-component {
            .top-padding {
                margin-top: pToR(25);
                line-height: 24px;
            }
            .fm-update-notes-wrapper {
                .mmota-updates__header {
                    margin: 32px auto 16px;
                }

                .mmota-updates__notes {
                    white-space: pre-line;
                    text-align: left;
                    color: $text-blue;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            .fm-update-pending-btn-wrapper {
                display: flex;
                flex-direction: column;
                align-self: flex-start;
                @include desktop {
                    flex-direction: row;
                    align-self: center;
                }
            }
        }
    }
}
