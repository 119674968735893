.brand-lincoln {
    .vehicle-checklist {
        .vehicle-checklist-text-container {
            .vehicle-checklist-icon {
                &.vehicle-checklist-software-update {
                    height: 40px;
                    width: 40px;
                    margin-right: 24px;
                }
            }
        }
    }
}
