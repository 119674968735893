.brand-ford {
    .articles-links-container {
        text-align: left;
        color: $f1-text-blue;
        max-width: 90rem;
        margin: 22px auto;
        padding: 0 17px 0 16px;
        @include desktop {
            margin: 100px auto;
            padding: 0 56px 0 56px;
        }
        .articles-links-title {
            font-family: $f1-font-regular;
            margin: 20px 0 0 0;
            font-size: pToR(40);
            line-height: 48px;
            text-align: left;
            @include desktop {
                font-size: pToR(54);
                line-height: 64px;
            }
        }
        .articles-links {
            display: flex;
            flex-direction: column;
            margin-top: 48px;
            @include desktop {
                max-width: 90rem;
                flex-flow: row;
            }
            .article {
                padding: 16px 0 16px 0;
                @include desktop {
                    padding: 0;
                }
            }
            .article-wrapper {
                display: flex;
                flex-direction: column;

                @include desktop {
                    align-content: space-between;
                    flex-direction: row;
                    width: 33.33%;
                }
                .article-header {
                    text-align: left;
                    font-family: $f1-font-regular;
                    line-height: 32px;
                    color: $f1-text-blue;
                    text-transform: none;
                    font-size: pToR(24);
                    min-height: unset;
                    margin-bottom: 20px;

                    @include desktop {
                        font-size: pToR(32);
                        line-height: 40px;
                        min-height: 80px;
                        margin-bottom: 16px;
                    }
                    @media (min-width: 1323px) {
                        min-height: unset;
                    }
                }
                .article-body {
                    padding-top: 0;
                }
                .article-list {
                    font-family: $f1-font-regular;
                    line-height: 24px;
                    font-size: pToR(16);
                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: $f1-text-blue;
                    }
                }
            }
            .article-divider {
                border-bottom: 1px solid $border-grey;
                width: 296px;
                margin: 0 0 0 34px;
                justify-content: center;

                @include desktop {
                    border-bottom-width: 0;
                    border-right: 1px solid $border-grey;
                    max-width: 36px;
                    margin: 66px 70px 0 34px;
                }
                @media (min-width: 992px) and (max-width: 1322px) {
                    margin: 105px 70px 0 34px;
                }
            }
        }
    }
}
