.brand-ford {
    .notifications-modal {
        .notifications-header {
            color: $fds-color-dk-gray;
            font-family: $f1-font-light;
            font-size: pToR(20);
            line-height: pToR(30);
            text-align: center;
        }

        .notifications-container {
            max-width: pToR(720);
            margin: 30px auto;

            hr.notifications-divider:first-child {
                @include mobile {
                    display: none;
                }
            }
        }

        .notifications-divider {
            margin: 30px 0 25px;
            box-shadow: none;
        }

        .modal-header {
            margin-bottom: pToR(56);

            @include mobile {
                margin-bottom: pToR(48);
            }
        }

        .modal-body {
            @include mobile {
                margin-top: 0;
            }
        }

        .modal-close {
            width: 18px;
            height: 24px;
            margin-right: 0;
            margin-top: 10px;

            img.close-icon {
                width: 18px;
            }
        }

        .modal-content {
            border-radius: 4px;
            width: 77%;

            @include mobile {
                width: 90%;
            }
        }

        .service-reminder-notification {
            .save-messages-container {
                display: flex;
                padding-top: 5px;
                padding-bottom: 10px;
                img {
                    width: pToR(24);
                    height: pToR(24);
                    padding-right: 10px;
                    &.error {
                        width: pToR(28);
                        height: pToR(28);
                    }
                }
                span {
                    color: $fds-color-dk-gray;
                    &.error {
                        color: $error-message-text;
                    }
                }
            }
            @include mobile {
                .details-subtext {
                    padding: 5px 15px;
                }
            }
        }
        .detailed-notification-item-wrapper
            .detailed-notification-item-header
            .details-message
            .details-message-inline-link {
            display: inline-block;
            float: right;
            margin-right: 0;
        }
    }
    .fm-notifications-modal {
        .fm-notifications-container {
            width: 100%;
            .detailed-notification-item-wrapper {
                width: 100%;
            }
        }
        .notifications-divider {
            margin: 16px 0;
            box-shadow: none;
        }
        .fm-modal-slot-component {
            @include desktop {
                padding: 55px;
            }
        }
    }
}
