.brand-lincoln {
    .smart-tile-icon {
        .icon {
            padding-bottom: 0.625rem;
            height: pToR(48);
            box-sizing: content-box;
            -webkit-box-sizing: content-box;
        }

        .description {
            color: $primary-graphite;
            font-family: $proxima-nova-regular;
            font-size: pToR(18);
            letter-spacing: 0;
            line-height: pToR(26);
            text-align: center;
            padding: 0 pToR(8);
        }

        .tooltip .smart-tile__tool-tip {
            font-size: small;
            color: $dark-blue;
            font-weight: bold;
            cursor: pointer;
            text-decoration: none;
        }
    }
}
