.brand-ford {
    .vehicle-selector-fds {
        box-shadow: $outer-drop-shadow;
        display: inline-block;
        background-color: $white;
        border-radius: 3px;
        padding: 32px 12px;
        text-align: center;
        margin: 60px auto 0;
        @include tablet {
            padding: 32px 36px;
        }
        @include desktop {
            margin: 100px auto 0;
        }
        .input-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            @include tablet {
                align-items: center;
            }
            @include desktop {
                flex-direction: row;
            }
        }
        .or {
            padding: 26px 0;
            min-width: 80px;
            margin: auto 32px;
            font-size: 14px;
        }
        .disclaimer {
            display: none;
            color: $search-text;
            font-family: $f1-font-regular;
            font-size: 0.75rem;
            line-height: 0.75rem;
            margin-top: 32px;

            @include tablet {
                display: inline-block;
            }
        }

        .fds-segmented-control__button-list-flex-container {
            margin-bottom: 30px;
            .fds-activity-indicator {
                margin: 6px 15px 0 15px;
                .fds-activity-indicator__progress-wrapper {
                    height: 0px;
                }
                .fds-activity-indicator__progress-wrapper:before {
                    height: 22px;
                    width: 22px;
                }
            }
        }
        .fds-segmented-control__panels {
            .fds-segmented-control__panel {
                &.fds-segmented-control__panel--show {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }
    .support-fds-wrapper {
        .owner-manual-content {
            .selector-label {
                font-family: $f1-font-light;
                color: $dark-blue;
                text-transform: uppercase;
                font-size: 1.125rem;
                line-height: 1.875rem;
                margin: 0;
            }
        }
    }
}
