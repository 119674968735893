.brand-lincoln {
    .ymm-vin-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 450px;
        @include desktop {
            align-items: flex-start;
        }
        .ymm-vin-header {
            flex: 1;
            margin: 0 0 0.75rem 0;
            .selector-label {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                text-transform: uppercase;
                font-size: 18px;
                letter-spacing: 3px;
                line-height: 1.875rem;
                font-weight: 300;
                text-align: center;
                @include md-desktop {
                    text-align: left;
                }
            }
        }
        .ymm-vin-body {
            .ymm-container-dropdown {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 100%;
                @include desktop {
                    flex-direction: row;
                }
                .dropdown-container-wrapper {
                    flex-direction: column;

                    @include mobile {
                        padding-bottom: 1.25rem;
                    }
                    .year-dropdown {
                        min-width: auto;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        @include desktop {
                            margin-right: 20px;
                            width: 162px;
                        }
                    }

                    .model-dropdown {
                        min-width: auto;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;

                        @include desktop {
                            margin-top: 0;
                            width: 272px;
                        }
                    }

                    .error-message {
                        top: -1.8rem;
                        @include tablet-portrait {
                            top: -3rem;
                        }
                    }
                }
            }
            .error-message {
                top: -1.8rem;
                @include tablet-portrait {
                    top: -3rem;
                }
            }
            .vehicle-selector-button {
                margin-top: 24px;
                @include desktop {
                    margin-top: 32px;
                }
            }
        }
    }
}
