.feature-trio.trio-carousel-padding {
    padding-right: unset;
}
.feature-trio {
    & .carousel {
        margin-top: 60px;
        & .carousel-wrap__items-item {
            width: calc(100vw - 50px);
            margin-left: 6px;
            & .card {
                height: 100%;
            }
        }
    }
}
