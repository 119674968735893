.brand-ford {
    .vehicle-notification-banner {
        padding: 14px 24px 14px 10%;
        display: flex;
        align-items: flex-end;
        position: relative;
        height: 54px;
        width: 100%;
        background-color: $fds-color-white;
        transition: top 0.3s;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);

        .vehicle-notification-content {
            display: flex;
            margin-left: auto;
            margin-right: 5%;
            cursor: pointer;
            border: none;
            background: transparent;

            &:hover {
                text-decoration: underline;
                color: $fds-color-secondary;
            }

            .vehicle-notification-title {
                font-family: $f1-font-regular, sans-serif;
                height: 26px;
                color: $fds-color-primary;
                font-size: 16px;
                text-align: center;
                line-height: 26px;
            }
            .vehicle-notifications-bell {
                position: relative;
                img {
                    margin-top: 3px;
                    margin-left: 10px;
                    height: 20px;
                }

                .vehicle-notification-count {
                    position: absolute;
                    left: 20px;
                    color: $fds-color-white;
                    background-color: $fds-color-red;
                    border-radius: 50%;
                    font-size: 12px;

                    line-height: 0;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    padding: 5px;
                }
                .vehicle-notification-count::after {
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                }
            }

            @include mobile {
                margin-right: auto;
                margin-left: auto;
            }
        }
        @include mobile {
            padding: 12px 24px 12px 24px;
            margin-right: auto;
            margin-left: auto;
        }
    }
}
