.brand-ford {
    .smart-tile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        min-height: 280px;
        background: white;
        box-shadow: $outer-drop-shadow;
        text-align: center;
        color: $text-gray;
        font-size: 1rem;
        position: relative;
        margin: 0.5rem 0;

        @include tablet {
            margin: 0.8rem;
            width: 290px;
        }

        .smart-tile-header {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 10px 0;
            font-family: $f1-font-light;
            font-size: pToR(16);
            text-transform: uppercase;
            line-height: 25px;
            border-radius: 3px 3px 0 0;
            margin: 0;
            &.none {
                background-color: $medium-gray;
            }

            &.good {
                background-color: $good;
            }

            &.moderate {
                background-color: $moderate;
            }

            &.severe {
                background-color: $severe;
            }
            &.blue {
                background-color: $light-blue;
            }

            &.good,
            &.blue,
            &.moderate,
            &.severe {
                color: white;
            }
            &.features-submitted {
                background-color: $good;
                color: white;
            }
            &.features-not-submitted {
                background-color: $severe;
                color: white;
            }
        }

        .smart-tile-button {
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: center;
            width: 100%;
            margin-bottom: 1.188rem;
            cursor: pointer;
        }

        .dynamic-description {
            text-transform: uppercase;
            font-size: pToR(10);
            line-height: pToR(26);
            text-align: center;
            font-family: $f1-font-regular;
        }
        .label {
            font-size: pToR(24);
            font-family: $f1-font-bold;
            line-height: pToR(34);
            text-align: center;
        }
    }
}
