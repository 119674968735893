.brand-lincoln {
    $feature-content-a-height: 620px;
    $feature-content-a-height-medium: 500px;

    .feature-content-a {
        position: relative;
        margin: 76px 0 0;
        height: $feature-content-a-height;

        @include tablet {
            height: $feature-content-a-height-medium;
            display: grid;
            grid-template-columns: 33% 1fr;
            margin: 6rem auto 4rem auto;
            max-width: 1440px;
        }

        a {
            text-decoration: none;
            display: inline-block;
        }

        img {
            height: $feature-content-a-height;

            @include tablet {
                height: $feature-content-a-height-medium;
            }
        }

        & > * {
            position: absolute;

            @include tablet {
                position: static;
            }
        }

        &__content {
            z-index: 1;
            color: $white;
            text-align: center;
            display: flex;
            width: 100%;
            height: $feature-content-a-height;
            background: rgba(16, 43, 78, 0.7);

            @include tablet {
                height: $feature-content-a-height-medium;
                background: #324047;
            }
        }

        &__content-wrapper {
            margin: auto;
            max-width: 80%;

            @include tablet {
                max-width: 307px;
            }
        }

        &__content-wrapper > * {
            margin: auto;
        }

        &__heading {
            font-family: $proxima-nova-light;
            font-size: 1.5rem;
            letter-spacing: 2px;
            color: white;
            text-transform: uppercase;
        }

        &__body-copy {
            font-family: $proxima-nova-regular;
            font-size: 0.7rem;
            letter-spacing: 1px;
            margin: 2rem;
            padding: 0 1rem;
            text-transform: uppercase;

            @include tablet {
                margin: 6px 0 24px;
            }
        }

        &__image {
            overflow: hidden;
            width: 100%;
            max-width: 100%;
        }

        &__cta.secondary-button-link {
            color: white;
        }
    }
}
