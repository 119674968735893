.brand-ford {
    .detailed-notification-item-wrapper {
        .header-notifications-divider {
            width: 80%;
            margin: 12px 42px 12px;
            box-shadow: none;
        }

        .detailed-notification-item-header {
            display: flex;
            @include mobile {
                flex-direction: column;
            }

            .icon-item {
                padding-right: 20px;
                padding-top: 5px;
                text-align: center;

                img {
                    height: auto;
                    width: 25px;
                }

                @include mobile {
                    padding-right: 0;
                }
            }

            .details-item {
                flex-grow: 2;
                text-align: left;
                @include mobile {
                    text-align: center;
                }
            }

            .details-message {
                color: $fds-color-primary;
                font-family: $f1-font-bold;
                font-size: pToR(24);

                .details-message-inline-link {
                    display: inline-block;
                    float: right;
                    margin-right: 3rem;
                }
            }

            .details-subtext {
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                font-size: pToR(12);
                line-height: pToR(26);
                margin-top: pToR(5);

                @include desktop {
                    width: 90%;
                }
            }
        }

        .details-list {
            .list-item {
                display: flex;

                @include desktop {
                    width: 95%;
                    padding-left: 42px;
                    padding-right: 42px;
                }

                .list-icon {
                    padding-left: 15px;
                    padding-right: 7px;
                    padding-top: 3px;
                    text-align: center;

                    img {
                        height: auto;
                        width: 16px;
                    }

                    @include mobile {
                        padding-right: 3px;
                    }
                }

                .list-text {
                    color: $fds-color-dk-gray;
                    font-family: $f1-font-light;
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            .notifications-item-divider {
                margin: 12px 0 12px;
                box-shadow: none;

                @include desktop {
                    width: 85%;
                    margin: 12px 50px 12px;
                }
            }

            .notification-accordion {
                padding-left: 50px;
                margin-top: 15px;
                padding-right: 15px;
                width: 94%;

                @include mobile {
                    padding: 0;
                    width: 100%;
                }

                .panel {
                    padding: 0 15px 0 35px;
                    text-align: left;
                    line-height: 32px;
                }

                .accordion-title {
                    padding: 15px;
                    text-align: left;

                    .list-item .list-icon {
                        padding-left: 0;

                        @include mobile {
                            padding-right: 7px;
                        }
                    }
                    .list-item {
                        padding-left: 0;
                    }
                }
            }
        }

        .button-wrapper {
            margin: 24px auto;
            @include mobile {
                flex-direction: column;
            }
            .secondary-button-cta {
                margin-left: 30px;
                border: 1px solid $fds-color-primary;
                border-radius: 20px;
                &:focus-visible {
                    border: 2px solid $primary-graphite;
                }
                @include mobile {
                    margin: 20px 30px 0px 30px;
                }
            }
        }
    }
    .fm-detailed-notification-item-wrapper {
        .fm-detailed-notification-item-header {
            font-size: 24px;
            line-height: 32px;
            display: flex;
            font-family: $f1-font-regular, sans-serif;
            color: $f1-text-blue;
            .fm-details-subtext {
                font-size: 16px;
                line-height: 24px;
                padding-top: 13px;
            }
        }
        .fm-details-list {
            .notifications-item-divider {
                box-shadow: none;
                padding-bottom: 16px;
            }
            .list-item {
                display: flex;
                padding-top: 16px;
                @include desktop {
                    width: 95%;
                }

                .list-icon {
                    padding-right: 16px;
                    padding-top: 3px;
                    text-align: center;

                    img {
                        height: auto;
                        width: 16px;
                    }

                    @include mobile {
                        padding-right: 3px;
                    }
                }

                .list-text {
                    color: $f1-text-blue;
                    font-family: $f1-font-light;
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
        .fm-button-wrapper {
            display: flex;
            justify-content: flex-end;
            padding: 16px 0 16px 0;
        }
    }
}
