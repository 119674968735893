@import '../../../../styles/main.scss';

.warranty-details-page {
    margin: 3.5% 7% 1.5% 7%;
    @include mobile {
        margin: 13% 25px 0 25px;
    }

    p > a {
        color: $fds-color-primary;
        text-decoration: none;
        border-bottom: 1px solid $fds-color-primary;

        &:hover,
        &:focus {
            color: $fds-color-secondary;
            border-bottom: 1px solid $fds-color-secondary;
        }
    }

    .warranty-details-page-title {
        h1 {
            height: 40px;
            font-size: 34px;
            line-height: 1.29;
            font-family: $f1-font-light;
            color: $fds-color-dk-gray;
            margin-bottom: 16px;
            @include mobile {
                height: auto;
                font-size: 34px;
                line-height: 44px;
                text-align: center;
            }
        }
    }

    .warranty-details-padding {
        padding: 20px;
    }

    .warranty-details-page-subtext {
        font-size: 16px;
        font-family: $f1-font-regular;
        color: $fds-color-dk-gray;
    }

    .warranty-details-page-auth-text,
    .warranty-terms-and-conditions {
        margin: 8px 0 40px;
        line-height: 26px;
        color: $fds-color-dk-gray;
        font-size: 16px;
        font-family: $f1-font-regular;
    }
    .warranty-vehicle-details {
        box-shadow: 0px 3px 3px 1px $fds-color-border-md-gray;
        padding: 15px 40px 10px 40px;
        @include mobile {
            padding-left: 25px;
            padding-right: 25px;
        }
        h2 {
            font-family: $f1-font-regular;
            color: $fds-color-dk-gray;
            line-height: 34px;
        }
        .warranty-vehicle-details-wrapper {
            display: inline-block;
            width: 100%;
        }
        .warranty-vehicle-details-left {
            float: left;

            .name-only {
                @include tablet {
                    margin-top: 22px;
                }
            }
        }
        .warranty-vehicle-details-info {
            .warranty-vehicle-start-date,
            .warranty-vehicle-status {
                @include desktop {
                    margin-top: 5px;
                    margin-right: 20px;
                    font-size: 16px;
                    line-height: 26px;
                    display: inline-block;
                }
                @include mobile {
                    margin-top: 20px;
                }
            }
            .warranty-vehicle-odometer {
                @include desktop {
                    font-size: 16px;
                    line-height: 26px;
                    display: inline-block;
                }
                @include mobile {
                    position: relative;
                    margin-top: 20px;
                }
            }
            .warranty-start-date-label,
            .warranty-odometer-label,
            .warranty-status-label {
                font-family: $f1-font-regular;
                color: $fds-color-dk-gray;
                @include mobile {
                    display: block;
                }
            }
            .warranty-start-date,
            .warranty-odometer,
            .warranty-status {
                font-family: $f1-font-regular;
                color: $fds-color-dk-gray;

                @include desktop {
                    margin-left: 10px;
                }
            }
            .warranty-status {
                text-transform: uppercase;
            }
            .tooltip {
                top: 8px;

                @include mobile {
                    position: absolute;
                    top: 16px;
                }
                .tooltip__infoIcon__dark {
                    font-size: x-large;
                }
                .tooltip__wrapper {
                    display: none;
                }
            }
        }
    }

    section.warranty-details-accordion > div:last-of-type {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }

    .accordion-section .accordion-title {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        @include mobile {
            padding: 25px 15px;
            flex-direction: column;

            .fds-chevron-wrap {
                position: absolute;
                align-self: flex-end;
                right: 25px;
                top: 25px;
            }

            .accordion-item1,
            .accordion-item2 {
                text-align: start;
                margin-right: auto;
            }

            .accordion-item2 {
                display: flex;
                align-self: baseline;
                padding-top: 5px;
                padding-right: 10px;
            }
        }

        @include desktop;
        @include tablet {
            .accordion-item1 {
                text-align: left;
                flex-basis: 40%;
            }
            .accordion-item2 {
                display: flex;
                align-self: baseline;
                margin-right: auto;
                text-align: start;
                padding-right: 10px;
                flex-basis: 40%;
            }
        }
    }

    .accordion-heading {
        color: $fds-color-primary;
        font-family: $f1-font-regular;
        font-size: 16px;
        line-height: 26px;
    }

    .accordion-subtext {
        color: $fds-color-dk-gray;
        font-family: $f1-font-regular;
        line-height: 21px;
        font-size: 11px;
    }
    .accordion-subtext-img {
        color: $fds-color-dk-gray;
        font-family: $f1-font-regular;
        line-height: 21px;
        font-size: 14px;
    }

    .accordion-text,
    .fds-chevron-text {
        color: $fds-color-dk-gray;
        font-family: $f1-font-regular;
        font-size: 16px;
        line-height: 26px;
    }

    .warranty-expiry-info-content {
        margin: 40px 0 auto;
        @include mobile {
            margin: 45px 0 0 0;
            a {
                word-wrap: break-word;
            }
        }
    }

    .warranty-details-accordion {
        margin: 40px 0 auto;
        @include mobile {
            margin: 45px 0 0 0;
        }
        .warranty-details-accordion-details {
            display: block;
            text-align: left;
            padding: 14px 20px 20px 24px;
            font-size: 15px;
            font-family: $f1-font-regular;
            line-height: 1.59;
            color: $fds-color-md-gray;
            background: $fds-color-surface;

            @include mobile {
                color: $fds-color-dk-gray;
            }

            ol {
                margin-top: 12px;
                list-style-type: disc;
                padding-left: 20px;

                li {
                    margin-top: 5px;
                    @include mobile {
                        line-height: 2;
                        padding-left: 10px;
                    }
                }
            }
        }
        .warranty-unavailable {
            margin-left: 36px;

            @include mobile {
                margin-left: 0;
            }
        }
        .accordion-base-warranty-status {
            .warranty-icon-placeholder,
            .warranty-status-icon {
                display: none;
            }
            .accordion-text {
                color: $fds-color-md-gray;
                background: $fds-color-surface;
            }
            .warranty-unavailable {
                margin-left: 0;
            }
        }
    }

    .warranty-header-content {
        text-align: center;
        font-family: $f1-font-regular;
        span {
            text-align: left;
        }

        .coverage-type {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    .warranty-status-wrap {
        @include desktop;
        @include tablet {
            margin-right: auto;
        }

        @include mobile {
            margin-left: 10px;
            margin-top: 10px;
        }
    }

    .warranty-status-icon {
        position: relative;
        top: 4px;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        margin-left: 10px;

        &.expired {
            top: 3px;
        }
    }

    .warranty-icon-placeholder {
        margin-right: 26px;
        margin-left: 10px;

        @include mobile {
            margin-right: 0;
        }
    }

    .warranty-esp {
        text-align: center;

        @include mobile {
            display: grid;
            text-align: left;
        }

        .warranty-esp-header {
            float: left;
        }

        .warranty-expired {
            font-size: 13px;
            color: $fds-color-md-gray;
            display: block;
        }

        .warranty-expired-icon {
            position: relative;
            top: 4px;
            width: 16px;
            height: 16px;
            margin-right: 20px;
        }
    }

    .warranty-esp-cta {
        padding: 40px 40px 40px 0px;
        text-align: left;
        @include mobile {
            padding-left: 25px;
        }
        p {
            a {
                margin-right: 12px;
                white-space: nowrap;
                margin-left: 6px;
                &::after {
                    content: $fds-chevron-right-unfilled;
                    display: inline-block;
                    margin-right: 8px;
                    font-size: 12px;
                    font-family: FdsFordIcons, sans-serif;
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    padding-right: 2px;
                    position: absolute;
                    color: $fds-color-dk-gray;
                }
            }
        }
    }

    .warranty-vehicle-title-right {
        display: flex;
        flex-direction: row;
        float: right;

        @include desktop {
            padding: 18px 0;
        }

        @include mobile {
            width: 100%;
            margin: 20px 0 20px 0;
            flex-direction: column;
            align-items: center;
        }

        a.primary-button {
            padding: 0 10px;
            margin: 0 15px;
        }

        .find-dealer-button {
            @include mobile {
                margin-top: 15px;
            }
        }
    }

    .warranty-vehicle-download-icon {
        width: pToR(24);
        height: pToR(24);
        margin-right: pToR(8);
    }

    .warranty-vehicle-download-button-format {
        display: flex;
        align-items: center;
        color: $fds-color-dk-gray;
        font-size: 16px;
        font-family: $f1-font-regular;
    }

    .warranty-service-error {
        color: $fds-color-dk-gray;

        @include mobile {
            padding: 20px;
        }

        h1 {
            height: 40px;
            margin: 30px 0;
            font-size: pToR(46);
            line-height: 44px;
            font-family: $f1-font-regular;

            @include mobile {
                margin: 0;
                font-size: pToR(34);
                text-align: center;
            }
        }

        p {
            font-family: $f1-font-regular;
            text-align: left;
            font-size: pToR(16);
            line-height: 26px;

            @include mobile {
                padding-top: 16px;
            }
        }
    }
    .warranty-start-date-error-msg {
        .error-message {
            margin-left: 0px !important;
            margin-top: 60px !important;
        }
        .secondary-button .secondary-button-link {
            padding: 0px;
            margin-top: 30px !important;
        }
    }
    .info-wrapper {
        position: relative;
        cursor: pointer;

        .odometer-info-icon {
            content: url('../../../../assets/info-red-icon.svg');
            position: absolute;
            margin: 0;
            padding: 0;
            font-weight: bold;
            width: 24px;
            height: 24px;
            color: $dark-blue;
            left: 10px;
        }
    }

    .warranty-terms-and-conditions {
        margin-top: 60px;
        table {
            max-width: fit-content;
        }
        h3 {
            line-height: 34px;
        }

        > ul,
        > ol {
            padding-left: 40px;

            > li {
                padding-bottom: 5px;
                > ul,
                > ol {
                    margin-left: 1.25rem;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
.popover-warranty-odometer {
    margin-left: -8px;
    &.popover-bottom-arrow {
        .popover-container::before {
            top: 199px;
            margin-left: 145px;
        }
        .popover-container::after {
            top: 199px;
            left: 135px;
        }
    }
}
@import './warranty-details.lincoln.scss';
