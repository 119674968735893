.brand-ford {
    .featured-articles-carousel-container {
        max-width: 90rem;
        touch-action: pan-y;
        justify-content: center;
        margin: 100px auto;

        .featured-articles-carousel-indicator {
            color: $fds-color-dk-gray;
            font-family: $f1-font-regular;
            font-size: 1rem;
            line-height: 26px;
            text-align: center;
        }
        .featured-articles-carousel-title {
            color: $dropdown-text;
            font-family: $f1-font-light;
            font-size: pToR(34);
            line-height: 44px;
            text-align: center;
            margin: 20px 0 0 0;
        }
        .featured-articles-carousel-subtitle {
            margin-top: 8px;
            color: $fds-color-dk-gray;
            line-height: pToR(26);
            font-size: pToR(16);
            font-family: $f1-font-regular;
            text-align: center;
        }
        .featured-articles-carousel {
            overflow: hidden;
            margin-top: 60px;
            width: 100vw;
            padding-left: 16px;
            @include mobile {
                margin-left: pToR(4);
            }
            @include tablet {
                margin-left: pToR(84);
            }
            .featured-articles-carousel-cards {
                display: flex;
                align-items: stretch;
                flex-wrap: nowrap;
                flex-direction: row;
                position: relative;
                padding-bottom: 60px;

                .card {
                    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
                    margin-bottom: 24px;
                    margin-right: 18px;
                    height: 100%;
                    width: calc(100vw - 50px);

                    @include tablet {
                        width: 450px;
                    }

                    @include desktop {
                        width: 400px;
                        margin-bottom: 0;
                        margin-right: 24px;
                    }
                }
            }
        }
        .featured-articles-carousel-controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 auto;
            justify-content: space-between;
            max-width: 260px;
            .primary-button::before {
                border-radius: 2px;
            }
            .disabled {
                &::before {
                    box-shadow: none;
                }
            }
            .featured-articles-carousel-button {
                padding: 0 1rem;
                margin: 0 1rem;
            }
            .featured-articles-carousel-slider-indicator {
                border: none;
                background: transparent;
                margin: 0;
                padding: 0;

                .featured-articles-carousel-slider-indicator-dash {
                    border-bottom: 3px solid #a9a9a9;
                    margin: 16px 5px 21px 7px;
                    padding: 0 7px;
                    &.active {
                        border-bottom: 3px solid $dark-blue;
                    }
                }
            }
        }
    }
}
