.brand-lincoln {
    .featured-articles-carousel-container {
        max-width: 90rem;
        touch-action: pan-y;
        justify-content: center;
        margin: 100px auto;

        .featured-articles-carousel-indicator {
            color: $primary-graphite;
            font-family: $proxima-nova-regular;
            font-size: pToR(18);
            letter-spacing: 0;
            line-height: pToR(22);
            text-align: center;
        }
        .featured-articles-carousel-title {
            line-height: pToR(32);
            margin: 20px 0 0 0;
            font-size: 1.5rem;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: $text-gray;
            text-align: center;
        }
        .featured-articles-carousel-subtitle {
            margin: 13px 16px 0 16px;
            color: $primary-graphite;
            line-height: pToR(26);
            font-size: pToR(18);
            font-family: $proxima-nova-regular;
            letter-spacing: 0;
            text-align: center;
            @include md-desktop {
                margin: 13px 100px 0 100px;
            }
        }
        .featured-articles-carousel {
            overflow: hidden;
            margin-top: 60px;
            width: 100vw;
            padding-left: 16px;
            @include mobile {
                margin-left: pToR(4);
            }
            @include tablet {
                margin-left: pToR(84);
            }

            .featured-articles-carousel-cards {
                display: flex;
                align-items: stretch;
                flex-wrap: nowrap;
                flex-direction: row;
                position: relative;
                padding-bottom: 60px;
                text-align: left;

                .card {
                    box-shadow: 0 0.3125rem 0.625rem 0.125rem lightgray;
                    margin-bottom: 24px;
                    margin-right: 18px;
                    height: 100%;
                    width: calc(100vw - 50px);

                    @include tablet {
                        width: 450px;
                    }

                    @include desktop {
                        width: 400px;
                        margin-bottom: 0;
                        margin-right: 24px;
                    }
                    .card-title {
                        letter-spacing: 0;
                    }
                    .primary-button {
                        min-width: pToR(150);
                    }
                }
            }
        }
        .featured-articles-carousel-controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 auto;
            justify-content: space-between;
            max-width: 241px;
            .disabled {
                &::before {
                    box-shadow: none;
                }
            }
            .featured-articles-carousel-button {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 20px;
                padding: 12px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: transparent;
                border: 1px solid;
                color: $brand-border;
                &:before {
                    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
                        0 10px 10px 0 rgba(0, 0, 0, 0.1);
                }
                &.disabled {
                    background-color: transparent;
                    color: $ghost-grey;
                    &:before {
                        background-color: $ghost-grey;
                    }
                }
                .fds-chevron {
                    &.left {
                        padding: 1px 1px 0 0;
                    }

                    &.right {
                        padding: 0 1px 0 0;
                    }
                }
            }
            .featured-articles-carousel-slider-indicator {
                border: none;
                background: transparent;
                margin: 0;
                padding: 0;

                .featured-articles-carousel-slider-indicator-dash {
                    border-bottom: 2px solid #a9a9a9;
                    margin: 22px 5px 21px 6px;
                    padding: 0 18px;
                    &.active {
                        border-bottom: 2px solid #f26146;
                    }
                }
            }
        }
    }
}
