.brand-lincoln {
    .mmota-modal {
        .modal-body {
            max-width: pToR(600);
            margin: 0 auto;
            color: $text-gray;
            font-family: $proxima-nova-regular;
            letter-spacing: 1px;
            line-height: pToR(26);
            text-align: center;
        }
        .mmota-modal-header,
        .mmota-updates__header {
            font-size: pToR(24);
            font-family: $proxima-nova-semi-bold;
            letter-spacing: 3px;
        }

        .mmota-list-body {
            list-style: none;
            font-family: $proxima-nova-regular;
            text-align: center;
            padding: 0 1rem;

            @include desktop {
                padding: 0 2rem;
            }
            .mmota-list-item {
                padding: 1rem;
            }
        }
        .secondary {
            padding-top: 2rem;
        }

        hr {
            box-shadow: none;
            margin: pToR(40) auto;
        }

        .mmota-updates__header {
            margin-top: pToR(80);
        }

        .mmota-modal-link {
            color: $brand-secondary;
            display: block;
            text-decoration: none;
            margin-top: pToR(25);

            @include xl-desktop {
                &.inline {
                    display: inline-block;
                }

                &.first {
                    float: left;
                }

                &.last {
                    float: right;
                }
            }
        }

        .top-padding {
            margin-top: pToR(25);
        }

        .mmota-updates__header-hr {
            margin: 0 auto 1rem;
        }

        .mmota-updates__notes {
            white-space: pre-line;
            text-align: left;
        }
    }
}
