@import '../../../styles/main.scss';

.warranty-information-section {
    @include desktop {
        margin: 3.5% 7% 1.5% 7%;
    }
    .vehicle-selector-fds {
        box-sizing: border-box;
        margin: 40px 0 50px;
        padding: 60px 50px 40px;
        width: 100%;

        .ymm-vin-container {
            justify-content: space-between;
            align-items: flex-start;
            @media (min-width: 480px) and (max-width: 768px) {
                width: 450px;
            }
            @include tablet {
                width: 80%;
                margin: 0 auto;
                height: 100%;
            }
            .ymm-vin-header {
                flex: unset;
            }
            .ymm-vin-tooltip {
                .tooltip__infoIcon {
                    &.dark {
                        font-size: 18px;
                        color: $fds-color-dk-gray;
                        top: unset;
                    }
                }
            }

            .ymm-vin-body .vin-input-container {
                padding-top: 0;
                .vin-input {
                    border-radius: 3px;
                    min-width: 100%;
                    max-width: 450px;
                    box-sizing: border-box;
                }
            }
        }

        .or {
            padding: 1.625rem 0;
            min-width: 5rem;
            margin: auto 1rem;
            font-size: 14px;
        }

        .input-container {
            @include desktop {
                min-height: 40px;
                display: grid;
                grid-template-columns: 3fr 1fr 3fr;
            }
            .fma-login {
                height: 100%;
                .vehicle-selector {
                    @include mobile {
                        margin: 30px 0 20px 0;
                    }
                    @include tablet {
                        margin: 30px 0 20px 0;
                    }
                }
                p {
                    @include mobile {
                        margin-bottom: 30px;
                    }
                }
            }

            .ymm-vin-container {
                height: 100%;
                @include mobile {
                    width: 80%;
                    margin: auto;
                    min-width: unset;
                }
                @include tablet {
                    width: 80%;
                    margin: auto;
                    min-width: unset;
                }
            }
        }

        .selector-label {
            label {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
    .back-to-ford-account-link {
        @include mobile {
            margin: 0 25px 30px;
        }
    }
    .vin-header-label {
        margin: 0;
        font-size: 1.125rem;
    }
    .support-fds-wrapper {
        @media screen and (max-width: $max-tablet) {
            margin-top: 45px;
        }
        .banner .top {
            height: 150px;
            justify-content: center;
            @include desktop {
                min-height: 150px !important;
            }

            .cta {
                padding-bottom: 0;
                .search-container {
                    padding: 50px;
                }
            }

            @include mobile {
                height: 78px !important;

                .cta {
                    .search-container {
                        .search-form {
                            height: 40px;
                            width: 100%;

                            .search-input {
                                height: 13px;
                                width: 223px;
                                font-size: 12px;
                                line-height: 13.87px;
                            }

                            .search-icon {
                                img {
                                    height: 14px;
                                    width: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .add-vehicle-banner {
            padding: 12px 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 8px solid $fds-color-green;
            position: fixed;
            width: 100%;
            background-color: $fds-color-white;
            z-index: 1999;

            div {
                display: flex;
                span {
                    margin-left: 15px;
                }
            }

            button {
                border: none;
                background-color: transparent;
                height: 14px;
            }

            .close-icon {
                height: 14px;
                width: 14px;
            }

            @include mobile {
                align-items: flex-start;

                img {
                    max-height: 24px;
                }

                button {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}
// Desktop CSS
@include desktop {
    .warranty-information-section {
        .ymm-vin-container {
            .ymm-vin-header {
                margin: 0 0 1.75rem 0;
            }

            .ymm-vin-body {
                .ymm-container-dropdown {
                    justify-content: flex-start;

                    .dropdown-container-wrapper {
                        padding-top: 0;

                        .label {
                            top: -25px;
                        }

                        .dropdown.model-dropdown {
                            width: 14.25rem;
                        }
                    }
                }
            }
        }

        .input-container {
            .vin-container {
                .vin-input-container {
                    height: 131px;
                }

                .selector-label {
                    white-space: nowrap;
                    position: absolute;
                }
            }
        }
    }
}
// Mobile CSS
@media screen and (max-width: $mobile-width) {
    .warranty-information-section {
        width: 100%;

        .vehicle-selector-fds {
            width: 100%;
            margin: 23px 0 30px;
            padding: 0px 20px 35px;

            .selector-label {
                margin-bottom: 20px;
            }

            .input-container {
                margin: auto;
                padding-top: 2rem;
                border-top: 1px solid rgba(151, 151, 151, 0.36);
            }
        }

        .content-2-button {
            .primary-button {
                margin: 30px auto 80px;
            }
        }
    }
}

.warranty-information-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0.3 1;

    @include mobile {
        flex-direction: column;
        padding-bottom: 40px;
        border-bottom: 1px solid $fds-color-md-gray;
        margin: 0 25px;
    }
}

.warranty-information-title {
    h1 {
        height: 40px;
        font-size: 34px;
        line-height: 1.29;
        font-family: $f1-font-light;
        color: $fds-color-dk-gray;
        @include mobile {
            height: 28px;
            font-size: 34px;
            line-height: 1.42;
        }
    }

    h2 {
        margin-top: 100px;
        font-size: 24px;
        line-height: 1.29;
        font-family: $f1-font-light;
        color: $fds-color-dk-gray;
        @include mobile {
            margin-top: 90px;
            font-size: 20px;
            line-height: 1.42;
        }
    }

    p {
        font-size: 17px;
        font-family: $f1-font-regular;
        line-height: 1.59;
        color: $fds-color-dk-gray;
        @include mobile {
            font-size: 16px;
        }
    }

    font-stretch: normal;
    font-style: normal;
    @include mobile {
        text-align: center;
    }
}

.warranty-quickguide-section {
    margin-top: 70px;

    .warranty-quickguide-heading {
        font-size: 24px;
        line-height: 1.29;
        font-family: $f1-font-light;
        color: $fds-color-dk-gray;
        @include mobile {
            margin-top: 40px;
            font-size: 20px;
            line-height: 1.42;
        }
    }

    .warranty-quickguide-subtext {
        p {
            font-size: 17px;
            font-family: $f1-font-regular;
            line-height: 1.59;
            color: $fds-color-dk-gray;
            padding-bottom: 20px;
            @include mobile {
                font-size: 16px;
            }
        }
    }
    @include mobile {
        padding: 0 25px;
    }
}
.warranty-quick-guide-accordion {
    @include mobile {
        padding: 0 15px;
    }
}
.warranty-related-topics {
    margin-bottom: 50px;
    text-align: left;

    h3 {
        margin-bottom: 30px;
    }

    p {
        font-size: 17px;
        line-height: 1.59;
        color: $fds-color-dk-gray;
        @include mobile {
            font-size: 16px;
        }
    }

    a {
        color: $dark-blue;
        font-family: $f1-font-regular;
        font-size: 18px;
        line-height: 10px;
        text-decoration: none;
    }
}
.warranty-vin-input-section {
    margin-top: 40px;
    padding: 45px 50px 62px 50px;
    box-shadow: 0 12px 10px 5px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;

    @include mobile {
        justify-content: center;
        margin: 0;
        height: auto;
        box-shadow: 0 12px 10px -5px rgba(0, 0, 0, 0.12);
        padding: 25px;
    }
    .warranty-garage-section {
        display: inline-block;
        padding: 0 50px 0 0;
        flex: 1;

        .heading-with-icon {
            min-height: 55px;
        }
        .dropdown-container-wrapper {
            padding-top: 0;
            .warranty-garage-dropdown-section {
                margin-top: 16px;

                .dropdown-button {
                    background-color: $fds-color-white;
                    box-sizing: border-box;
                    height: 50px;
                    border: 1px solid $fds-color-md-gray;
                    border-radius: 3px;
                }

                @include mobile {
                    margin-top: 0;
                }
            }
        }
        @include mobile {
            width: 100%;
            display: block;
            padding: 0;
        }
    }

    .heading-with-icon {
        @include mobile {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 25px;
        }
    }

    h4,
    h2 {
        display: inline;
        font-size: 20px;
        line-height: 1.29;
        font-family: $f1-font-light;
        font-stretch: normal;
        font-style: normal;
        color: $fds-color-primary;

        @include mobile {
            font-size: 24px;
            line-height: 1.49;
        }
    }

    .info-wrapper {
        display: inline;
        position: relative;

        img {
            display: inline;
            position: absolute;
            left: 8px;
            width: 20px;
            height: 20px;
        }
    }

    label {
        display: block;
        padding: 0 0 15px 0;
    }

    .divider-section {
        display: block;
        width: auto;
        position: relative;
        color: $fds-color-dk-gray;
        align-self: center;
        margin: 45px 68px 40px auto;

        @include mobile {
            margin: 0 68px 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        span {
            padding-left: 30px;

            @include mobile {
                padding: 15px;
            }
        }

        hr {
            position: absolute;
            width: 18px;
            border: 0.1px solid $fds-color-dk-gray;
            box-shadow: none;

            @include mobile {
                position: unset;
                margin: 0;
            }

            &.before-dash {
                top: 9px;
                display: inline-block;
            }

            &.after-dash {
                margin-left: 9px;
                top: 9px;
                display: inline-block;

                @include mobile {
                    margin-left: 0px;
                }
            }
        }
    }

    .see-your-warranty-button {
        margin-top: 30px;

        .button-label {
            margin: 0 15px 0 10px;
        }

        @include mobile {
            margin: 30px auto;
        }
    }

    .vehicle-details-wrapper {
        display: flex;
        flex-wrap: wrap;
        @include mobile {
            flex-direction: column;
        }

        .enter-your-vin-section {
            display: inline-block;
            flex: 1;

            .vin-info-icon {
                content: url('../../../assets/info-icon.svg');
            }

            @include mobile {
                width: 100%;
                text-align: center;
                padding-left: 0;

                .heading-with-icon {
                    margin-top: 10px;
                }

                label {
                    margin-top: 19px;
                    margin-bottom: 11px;

                    img.error-icon {
                        width: 18px;
                    }
                }
            }
        }

        .vin-textField {
            width: 100%;
            box-sizing: border-box;
            height: 50px;
            border: 1px solid $fds-color-md-gray;
            border-radius: 3px;

            @include desktop {
                max-width: 450px;
            }
        }

        .input-field-error {
            border-color: $error-text;
            border-radius: pToR(1);
        }

        .error-message {
            white-space: normal;
        }
    }
}

.brand-ford {
    .popover-warranty-vehicle-vin-popover {
        .popover-container::before {
            left: 297px !important;
            top: 420px;
        }
    }
    .warranty-vin-input-section {
        .see-your-warranty-button.vin {
            margin-top: 50px;
        }
        .vehicle-details-wrapper .enter-your-vin-section label {
            @include mobile {
                margin: 0;

                img.error-icon {
                    width: 16px;
                }
            }
        }

        .vehicle-details-wrapper .login-section .login-btn-wrap {
            display: flex;

            @include mobile {
                align-items: center;
                flex-direction: column;
                padding: 0 40px;
            }
            .primary-button {
                @include desktop {
                    margin-right: 32px;
                }
            }
        }
    }
    .enter-your-vin-section {
        .input-field-container.error {
            input[value=''] + label {
                color: $fds-color-dk-gray;
            }
            &:focus-within {
                input[value=''] + label {
                    color: $error-message-text;
                }
            }
        }
    }
}

.popover-container::before {
    content: '';
    position: absolute;
    left: 330px;
    top: 298px;
    width: 11px;
    @include mobile {
        display: none;
    }
}

.popover .popover-container {
    .popover-content {
        max-height: 512px;
    }
    .popover-heading {
        h2 {
            color: $fds-color-dk-gray;
            font-family: $f1-font-regular, Arial, Helvetica, sans-serif;
            font-size: 16px;
            line-height: 26px;
            padding-right: 50px;
            margin-bottom: 8px;
        }
    }
}

@import './warranty-information.lincoln.scss';
