.brand-ford {
    .vehicle-portal {
        .breadcrumbs.syndicate-header {
            @include mobile {
                padding-left: 1.5rem;
            }
        }
        .smart-tile-grid-wrapper.generic-grid,
        .smart-tile-grid-wrapper.dashboard {
            margin-top: 0px;
            @include desktop {
                margin-top: -50px;
            }
            .inner-smart-tile-grid {
                margin-top: unset;
            }
        }
        .banner {
            .top {
                @include desktop {
                    min-height: 21.8rem !important;
                    height: 450px;
                }
                .cta {
                    max-width: $max-content-width;
                    margin: 0 auto;
                    padding: 32px 16px 48px 16px;
                    display: flex;
                    flex-direction: column;
                    @include desktop {
                        flex-direction: row;
                        padding: 0 25px;
                        gap: 99px;
                    }
                    @include fds-large {
                        padding-left: 100px;
                        padding-right: 57px;
                    }

                    .vehicle-image-na {
                        width: 390px;
                        object-fit: contain;
                        margin-left: -16px;
                        @include desktop {
                            width: 527px;
                        }
                    }
                    .vehicle-image {
                        width: 390px;
                        object-fit: contain;
                        padding-left: 30px;
                        @include desktop {
                            width: 527px;
                            margin-left: -16px;
                            padding-left: 0;
                        }
                    }
                }
            }
        }
        .vehicle-portal-cta {
            color: $breadcrumb-blue;
            font-family: $f1-font-regular;
            @include desktop {
                text-align: left;
            }
            .vin {
                margin: 16px auto 24px;
                max-width: $max-content-width;
            }

            .greeting-header {
                color: $f1-text-blue;
                font-size: 24px;
                text-transform: capitalize;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 24px;
                text-align: left;
                text-decoration: unset;
                @include desktop {
                    margin-top: 116px;
                }
                .vehicle-portal-desc {
                    font-size: 36px;
                    line-height: 44px;
                    text-align: left;
                    color: $f1-text-blue;
                    text-decoration: unset;
                    text-transform: none;
                    @include desktop {
                        font-size: 48px;
                        line-height: 56px;
                    }
                }
            }
            .vehicle-portal-header {
                font-family: $f1-font-regular, sans-serif;
                color: $f1-text-blue;
                font-size: 24px;
                line-height: 32px;
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 16px;
                margin: 0;
                padding-bottom: 16px;
                width: 100%;
                @include desktop {
                    gap: 3px;
                    padding-bottom: 11px;
                    margin-top: 116px;
                }
            }
            .vehicle-portal-subhead {
                font-family: $f1-font-regular;
                color: $f1-text-blue;
                font-size: 36px;
                line-height: 44px;
                text-align: left;
                display: unset;
                @include desktop {
                    font-size: 48px;
                    line-height: 56px;
                }
            }
            .vehicle-portal-desc {
                font-size: 16px;
                line-height: 24px;
                text-align: left;
                color: $f1-text-blue;
            }

            .second-signin-button {
                margin: 0;
                display: inline;
                .secondary-button-link {
                    padding: 0;
                    font-family: $f1-font-regular;
                    color: $f1-text-blue;
                    text-decoration: underline;
                    text-underline-offset: 2px;
                    height: unset;
                    font-size: 16px;
                    &:hover {
                        color: $grabber-blue;
                    }
                }
            }
        }
        .skinny-banner-section {
            margin-top: 48px;
            @include desktop {
                margin-top: 96px;
            }
        }
        .connected-vehicle-bar-grid-preimagine {
            margin-top: 48px;
            @include desktop {
                margin-top: 96px;
            }
        }
        .quick-links-grid-wrapper {
            margin-top: 48px;
            margin-bottom: 0;

            @include desktop {
                margin-top: 96px;
                margin-bottom: 0;
            }
        }
        .vehicle-portal-feature-article-carousel-header {
            margin: 64px 16px 0 16px;
            @include desktop {
                margin: 96px 16px 0 56px;
            }
            .vehicle-portal-feature-article-carousel__title {
                font-size: 40px;
                line-height: 48px;
                color: $fm-text-grey;
                font-family: $f1-font-regular;
                @include desktop {
                    font-size: 54px;
                    line-height: 64px;
                }
            }

            .vehicle-portal-feature-article-carousel__subtitle {
                margin: 16px 16px 0 0;
                font-size: 16px;
                line-height: 24px;
                color: $f1-text-blue;
                font-family: $f1-font-regular;
            }
        }
        .fm-carousel__container {
            margin-top: 0;
            &.unimode {
                padding-right: 0px;
                @include desktop {
                    padding-right: 56px;
                }
            }
        }
        .fm-full-width-banner {
            margin-top: 48px;
            @include desktop {
                margin-top: 96px;
            }
            .fm-full-width-banner__button {
                margin-left: -24px;
            }
        }
        .articles-links-container {
            margin-top: 48px;

            @include desktop {
                margin-top: 96px;
            }
        }
    }
}
