.brand-ford {
    $feature-content-a-height: 620px;
    $feature-content-a-height-medium: 500px;

    .feature-content-a {
        position: relative;
        margin: 60px auto;
        height: $feature-content-a-height;

        @include tablet {
            height: $feature-content-a-height-medium;
            display: grid;
            grid-template-columns: 33% 1fr;
            margin: 100px auto;
            max-width: 1440px;
        }

        a {
            text-decoration: none;
            display: inline-block;
        }

        img {
            height: $feature-content-a-height;

            @include tablet {
                height: $feature-content-a-height-medium;
            }
        }

        & > * {
            position: absolute;

            @include tablet {
                position: static;
            }
        }

        &__content {
            z-index: 1;
            color: $white;
            text-align: center;
            display: flex;
            width: 100%;
            height: $feature-content-a-height;
            background: rgba(16, 43, 78, 0.7);

            @include tablet {
                height: $feature-content-a-height-medium;
                background: $dark-blue;
            }
        }

        &__content-wrapper {
            margin: auto;
            max-width: 80%;

            @include tablet {
                max-width: 307px;
            }
        }

        &__content-wrapper > * {
            margin: auto;
        }

        &__heading {
            font-family: $f1-font-light;
            font-size: 2.25rem;
            color: $white;
        }

        &__body-copy {
            font-family: $f1-font-regular;
            line-height: 26px;
            margin: 2rem;
            padding: 0 1rem;

            @include tablet {
                margin: 2rem 0;
            }
        }

        &__image {
            overflow: hidden;
            width: 100%;
            max-width: 100%;
        }
        .feature-content-a__content-wrapper {
            .feature-content-a__cta {
                font-family: $f1-font-regular;
                font-size: 1rem;
                background-color: $white;
                border-radius: 30px;
                color: $dark-blue;
                line-height: 1rem;
                border: none;
                box-shadow: 0 1rem 1rem 0 rgba(black, 0.1),
                    0 2rem 2rem 0 rgba(black, 0.1);
                transition: transform 0.3s ease-in-out,
                    background-color 0.3s ease-in-out,
                    box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
                padding: 0 1.875rem;
                height: 2.5rem;
                text-align: center;

                .fds-arrow.filled {
                    font-size: 1.2rem;
                    color: $dark-blue;
                    background-color: $white;
                }
            }
        }
    }
}
