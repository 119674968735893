.brand-ford {
    .vehicle-tabs-nav-container {
        width: auto;
        height: 71px;
        display: flex;
        justify-content: space-between;
        background-color: $breadcrumb-blue;
        .item1 {
            width: 100%;
            @include tablet {
                width: 432px;
            }
            height: 100%;
            border-right: 1px solid rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            color: $white;
            .dropdown-container-wrapper {
                width: 100%;
                padding: 0;
                .dropdown-button {
                    border: none;
                    background: transparent;
                }
                .dropdown-current-item {
                    color: $white;
                    align-items: center;
                    padding-left: 8px;
                    font-size: 16px;
                    & > span {
                        @include tablet {
                            padding-left: 76px;
                        }
                        text-align: left;
                        & span:nth-child(2) {
                            font-size: pToR(11);
                        }
                    }
                }
                .dropdown-container {
                    .dropdown-items-panel {
                        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
                            0 10px 10px 0 rgba(0, 0, 0, 0.1);
                        margin-top: 13px;
                        z-index: 2000;
                        border: none;
                        @include tablet {
                            width: 432px;
                        }
                    }
                    .dropdown-item {
                        font-size: 16px;
                        padding-left: 24px;
                        height: 71px;
                        display: grid;
                        align-items: center;
                        &.selected-item {
                            background-color: $white;
                            outline: 1px solid $breadcrumb-blue;
                            outline-offset: -10px;
                        }
                        &:not(:last-child) {
                            border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
                        }
                        @include tablet {
                            padding-left: 100px;
                        }
                        &:hover {
                            background-color: $grabber-blue;
                            color: $white;
                            outline: unset;
                            .add-change-vehicle-button {
                                color: $white;
                            }
                        }
                        & span:nth-child(2) {
                            font-size: pToR(11);
                        }

                        & .add-change-vehicle-button {
                            font-family: $f1-font-regular;
                            text-decoration: none;
                            background-color: transparent;
                            border: none;
                            cursor: pointer;
                            color: $breadcrumb-blue;
                            font-size: 16px;
                            line-height: 24px;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            & fds-chevron.right {
                                display: inline-block;
                            }
                            &:hover {
                                color: $white;
                            }
                        }
                    }
                }
                .vehicle-option-label {
                    display: block;
                    line-height: 18px;
                }
            }
            &:hover {
                background-color: $grabber-blue;
            }
        }
    }
}
