.brand-ford {
    .feature-trio {
        max-width: $max-content-width;
        text-align: center;
        color: $text-gray;
        margin: 60px auto;
        padding: 0 16px;
        @include tablet {
            margin: 100px auto;
            padding: 0 100px;
        }
        @include md-desktop {
            margin: 100px auto;
        }

        .feature-trio-heading-two {
            font-family: $f1-font-regular;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .secondary-button > a {
                padding-right: 0;
                padding-top: 24px;
            }
            .secondary-button-text {
                display: none;

                @include tablet {
                    display: block;
                }
            }
            @include tablet {
                padding-left: unset;
                padding-right: unset;
            }
        }

        .feature-trio-cards__title-two {
            margin: 0;
            font-size: pToR(20);
            line-height: 30px;
            text-align: left;
            padding-top: 19px;
            padding-bottom: 8px;
        }
        .feature-trio-cards__divider {
            margin: 0 auto;
            box-shadow: unset;
        }
        .feature-trio-title {
            margin: 20px 0;
        }
        .feature-trio-subtitle {
            margin-top: 20px;
            color: $fds-color-dk-gray;
            line-height: pToR(26);
            font-size: pToR(16);
            font-family: $f1-font-regular;
        }
        .feature-trio-cards {
            margin-top: 60px;
            display: grid;
            grid-gap: 20px;
            grid-template-columns: auto;
            justify-content: center;
            grid-auto-columns: 1fr;
            @include tablet {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
            @include md-desktop {
                grid-template-columns: repeat(3, minmax(0, 1fr));
                justify-content: space-between;
            }
            .card-wrap {
                margin-bottom: 20px;
            }
        }
    }
}
