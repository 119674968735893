.brand-lincoln {
    .popover-warranty-vehicle-vin-popover {
        .popover-container::before {
            left: 297px !important;
            top: 420px;
        }
    }
    .warranty-information-section-header {
        @include mobile {
            border-bottom: none;
        }
    }
    .warranty-information-title h1 {
        font-size: 32px;
        font-family: $proxima-nova-regular;
        color: $brand-secondary;
    }

    .warranty-vin-input-section {
        box-shadow: none;
        border: 1px solid $brand-gray3;
        background: $brand-gray1;

        h2 {
            font-size: 17px;
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            letter-spacing: normal;
        }

        .divider-section {
            color: $brand-secondary;
        }
    }
    .enter-your-vin-section {
        .input-field-container.error {
            input[value=''] + label {
                color: $primary-graphite;
            }
            &:focus-within {
                input[value=''] + label {
                    color: $brand-light-error;
                }
            }
        }
        label {
            font-size: 13px;
        }
        .vin-info-icon {
            content: url('../../../assets/info-red-icon.svg');
        }

        .error-message {
            font-family: $proxima-nova-regular;
            color: $brand-light-error;
            font-size: 13px;
        }
    }

    .warranty-information-title h1,
    .warranty-quickguide-section .warranty-quickguide-heading,
    .warranty-related-topics a,
    .login-section .login-desc,
    .enter-your-vin-section label {
        font-family: $proxima-nova-regular;
        color: $brand-secondary;
        letter-spacing: normal;
    }

    .warranty-information-title p,
    .warranty-quickguide-section .warranty-quickguide-subtext p {
        font-size: 18px;
        font-family: $proxima-nova-regular;
        color: $brand-secondary;
        letter-spacing: normal;
    }
    .popover .popover-container .popover-heading {
        h2 {
            color: $brand-white;
            font-family: $proxima-nova-regular, Arial, Helvetica, sans-serif;
            font-size: 13px;
            letter-spacing: 1px;
            line-height: 26px;
            padding-right: 50px;
            margin-bottom: 8px;
        }
    }
}
