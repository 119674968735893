@import '../../../../styles/main.scss';

.brand-lincoln {
    .warranty-quick-guide {
        .accordion-title {
            font-size: 17px;
            font-family: $proxima-nova-regular;
            line-height: 1.59;
            letter-spacing: normal;
            color: $brand-secondary;
        }
        .warranty-guide-accordion-details {
            p {
                text-align: left;
                padding: 14px 24px;
                font-size: 18px;
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                line-height: unset;
                letter-spacing: normal;
                background: $fds-color-surface;
            }
            a {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
                text-decoration: none;
                border-bottom: 2px solid $brand-border;

                &:hover,
                &:focus {
                    color: $brand-secondary;
                    border-bottom: 2px solid $brand-secondary;
                }
            }
        }
    }
}
