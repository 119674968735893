.brand-lincoln {
    .feature-trio {
        padding: 0 15px;
        max-width: 1440px;
        margin: 60px auto;
        @include desktop {
            margin: 100px auto;
        }

        .feature-trio-heading-two {
            font-family: $f1-font-regular;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .secondary-button > a {
                padding-right: 0;
                padding-top: 24px;
            }
            .secondary-button-text {
                display: none;

                @include tablet {
                    display: block;
                }
            }
        }

        .feature-trio-cards__title-two {
            margin: 0;
            font-size: pToR(20);
            font-weight: 300;
            line-height: 30px;
            letter-spacing: pToR(3);
            text-align: left;
            padding-top: 19px;
            padding-bottom: 8px;
        }
        .feature-trio-cards__divider {
            margin: 0 auto;
            box-shadow: unset;
        }

        .feature-trio-title {
            margin: 20px 0 0 0;
            font-family: $proxima-nova-regular;
            font-size: 1.5rem;
            letter-spacing: 2px;
            color: $text-gray;
            text-align: center;
            text-transform: uppercase;
        }
        .feature-trio-subtitle {
            margin: 18px 0 0 0;
            font-size: 1.1rem;
            color: $text-gray;
            text-align: center;
        }
        .feature-trio-cards {
            margin-top: 60px;
            display: flex;
            align-items: stretch;
            flex-direction: column;

            @include desktop {
                justify-content: center;
                padding: 0 26px 0 26px;
                flex-direction: row;
            }
            .card-wrap {
                width: 100%;

                .card {
                    margin-bottom: 24px;

                    @include tablet {
                        width: 450px;
                    }

                    @include desktop {
                        width: 360px;
                        margin-bottom: 0;
                        margin-right: 24px;
                    }
                }
            }
        }
    }
}
