.brand-lincoln {
    .warranty-details-page {
        p > a {
            color: $brand-secondary;
            font-family: $proxima-nova-semi-bold;
            text-decoration: none;
            border-bottom: 2px solid $brand-border;
            letter-spacing: normal;

            &:hover,
            &:focus {
                color: $brand-secondary;
                border-bottom: 2px solid $brand-secondary;
            }
        }

        .warranty-details-page-subtext,
        .warranty-details-page-auth-text,
        .warranty-vehicle-download-button-format,
        .warranty-vehicle-details h2,
        .warranty-details-page-title h1,
        .warranty-vehicle-details
            .warranty-vehicle-details-info
            .warranty-start-date-label,
        .warranty-vehicle-details
            .warranty-vehicle-details-info
            .warranty-odometer-label,
        .warranty-vehicle-details
            .warranty-vehicle-details-info
            .warranty-start-date,
        .warranty-vehicle-details
            .warranty-vehicle-details-info
            .warranty-odometer,
        .accordion-text,
        .accordion-heading,
        .accordion-subtext,
        .fds-chevron-text,
        .warranty-details-accordion .warranty-details-accordion-details {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            letter-spacing: normal;
        }

        .warranty-vehicle-details {
            box-shadow: none;
            border: 1px solid $brand-gray3;
            background: $brand-gray1;

            .primary-button.color-light {
                background: transparent;
            }
        }

        .info-wrapper {
            .odometer-info-icon {
                content: url('../../../../assets/info-red-icon.svg');
            }
        }
    }
    .popover-warranty-odometer {
        &.popover-bottom-arrow {
            .popover-container {
                border-color: $brand-primary;
                &::before {
                    margin-left: 144px;
                    top: 167px;
                }
                &::after {
                    left: 134px;
                    top: 168px;
                }
            }
        }
    }
}
