.brand-lincoln {
    .smart-tile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        min-height: 280px;
        background: white;
        text-align: center;
        color: #324047;
        font-size: 0.9rem;
        position: relative;
        margin: 0.5rem 0;
        border: 1px solid $brand-gray3;

        @include tablet {
            margin: 0.8rem;
            width: 290px;
        }

        .smart-tile-header {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 8px 0;
            font-family: $proxima-nova-bold;
            text-transform: uppercase;
            line-height: 25px;
            font-size: 0.8rem;
            letter-spacing: 2px;

            &.none {
                background-color: $medium-gray;
            }

            &.good {
                background-color: $good;
            }

            &.moderate {
                background-color: $moderate;
            }

            &.severe {
                background-color: $severe;
            }

            &.good,
            &.moderate,
            &.severe {
                color: white;
            }
        }

        .smart-tile-button {
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: center;
            width: 100%;
            margin-bottom: 1.188rem;
            cursor: pointer;
        }
        .dynamic-description {
            letter-spacing: pToR(0.77);
            font-size: pToR(10);
            line-height: pToR(26);
            text-align: center;
            font-family: $proxima-nova-regular;
        }
        .label {
            font-size: pToR(24);
            font-family: $proxima-nova-light;
            letter-spacing: pToR(2);
            line-height: pToR(34);
            font-weight: bold;
            text-align: center;
        }
    }
}
