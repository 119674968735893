@import '../../../styles/main.scss';
.brand-lincoln {
    .vehicle-checklist {
        display: flex;
        flex-direction: column;
        height: 213px;
        width: 395px;
        @include mobile {
            width: 363px;
        }
        box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1),
            0 10px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: $brand-white;
        justify-content: space-between;

        .vehicle-checklist-text-container {
            align-content: center;
            padding-left: 24px;
            .vehicle-checklist-heading {
                width: 100%;
                color: $brand-primary;
                font-family: $proxima-nova-regular;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 29px;
                padding-top: 24px;
                display: flex;
                justify-content: space-between;
                text-transform: uppercase;
            }

            .vehicle-checklist-icon {
                height: 40px;
                width: 40px;
                margin-right: 24px;
            }

            .vehicle-checklist-description {
                color: $brand-dark-grey;
                font-family: $proxima-nova-regular;
                font-size: 14px;
                letter-spacing: 1px;
                line-height: 21px;
                width: 267px;
            }
        }
        .secondary-button {
            .vehicle-checklist-cta {
                padding-left: 24px;
                padding-bottom: 12px;
                justify-content: flex-start;
            }
        }
    }
}
