.brand-ford {
    .notification-item-wrapper {
        display: flex;
        @include mobile {
            flex-direction: column;
        }

        .icon-item {
            padding-right: 20px;
            padding-top: 5px;
            text-align: center;

            img {
                height: 25px;
                width: 30px;
            }

            @include mobile {
                padding-right: 0;
            }
        }

        .details-item {
            flex-grow: 2;
            text-align: left;
            @include mobile {
                text-align: center;
            }
        }

        .details-message {
            color: $fds-color-primary;
            font-family: $f1-font-bold;
            font-size: pToR(24);
        }

        .details-subtext {
            color: $fds-color-dk-gray;
            font-family: $f1-font-regular;
            font-size: pToR(12);
            line-height: pToR(26);
            margin-top: pToR(5);

            @include desktop {
                width: 90%;
            }
        }

        .button-item {
            margin: auto;
            @include mobile {
                margin-top: 24px;
            }
        }
    }
    .fm-notification-item-wrapper {
        display: flex;
        @include mobile {
            flex-direction: column;
        }

        .fm-details-item {
            flex-grow: 2;
            text-align: left;
        }

        .fm-details-message {
            color: $f1-text-blue;
            font-family: $f1-font-regular;
            font-size: 24px;
            line-height: 32px;
        }

        .fm-details-subtext {
            color: $f1-text-blue;
            font-family: $f1-font-light;
            font-size: 16px;
            line-height: 24px;
            margin-top: pToR(5);

            @include desktop {
                width: 90%;
            }
        }

        .fm-button-item {
            display: flex;
            justify-content: flex-end;
            @include mobile {
                margin-top: 24px;
            }
        }
    }
}
