.brand-lincoln {
    .notifications-modal {
        .notifications-header {
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 2px;
            line-height: pToR(30);
            text-align: center;
            padding: 0px;
        }

        .notifications-container {
            max-width: pToR(720);
            margin: 30px auto;

            hr.notifications-divider:first-child {
                @include mobile {
                    display: none;
                }
            }
        }

        .notifications-divider {
            margin-top: 30px;
            margin-bottom: 25px;
            box-shadow: none;
        }

        .modal-header {
            margin-bottom: pToR(56);

            @include mobile {
                margin-bottom: pToR(48);
            }
        }

        .modal-body {
            @include mobile {
                margin-top: 0;
            }
        }

        .modal-close {
            img.close-icon {
                width: 18px;
                color: $brand-secondary;
            }
        }

        .modal-content {
            border-radius: 4px;
            width: 77%;

            @include mobile {
                width: 90%;
            }
        }

        .service-reminder-notification {
            .save-messages-container {
                display: flex;
                padding-top: 5px;
                padding-bottom: 10px;
                img {
                    width: pToR(24);
                    height: pToR(24);
                    padding-right: 10px;
                    &.error {
                        width: pToR(28);
                        height: pToR(28);
                    }
                }
                span {
                    color: $brand-secondary;
                    &.error {
                        color: $error-message-text;
                    }
                }
            }
            @include mobile {
                .details-subtext {
                    padding: 5px 15px;
                }
            }
        }

        .detailed-notification-item-header {
            .details-message {
                font-family: $proxima-nova-semi-bold;
                font-size: 24px;
                color: $brand-secondary;
                letter-spacing: 2px;
                line-height: pToR(30);
                text-align: left;
                font-weight: normal;
                margin-top: 0px;

                @include mobile {
                    width: 90%;
                    text-align: center;
                }
            }

            .details-message-inline-link {
                display: inline-block;
                float: right;
                margin-right: 0px;
                padding-left: 20px;
                padding-right: 0px;

                .secondary-button .secondary-button-link {
                    height: unset;
                }
                .secondary-button-text {
                    font-size: 16px;
                    letter-spacing: 0px;
                }
            }

            .details-subtext {
                font-family: $proxima-nova-regular;
                font-size: 18px;
                margin-top: 5px;
            }
        }

        .details-list .notification-accordion .accordion-title .list-item {
            padding-left: 0;
        }

        .detailed-notification-item-wrapper
            .detailed-notification-item-header
            .icon-item {
            padding-right: 20px;
            padding-top: 5px;
            text-align: center;
        }
        .detailed-notification-item-wrapper
            .detailed-notification-item-header
            .icon-item
            img {
            height: auto;
            width: 25px;
        }
        .notification-item-wrapper .icon-item img {
            height: 25px;
            width: 30px;
        }
        .primary-button .button-label {
            white-space: nowrap;
            margin-right: 8px;
        }
        .notification-item-wrapper .details-message {
            font-family: $proxima-nova-semi-bold;
            font-size: 1.5rem;
            font-weight: bold;
            letter-spacing: 2px;
        }
        .secondary-button .fds-chevron .fds-arrow.filled {
            color: #324047;
            margin-right: 8px;
            height: 8px;
            width: 12px;
            font-weight: 900;
        }

        .detailed-notification-item-wrapper
            .detailed-notification-item-header
            .details-subtext {
            letter-spacing: 0px;
            line-height: 1.625rem;
        }
    }
}
