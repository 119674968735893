.brand-lincoln {
    .vehicle-checklist {
        .vehicle-checklist-text-container {
            .vehicle-checklist-warranty-date {
                color: $brand-dark-grey;
                font-family: $proxima-nova-regular;
                font-size: 14px;
                letter-spacing: 1px;
                line-height: 21px;
            }
        }
    }
}
