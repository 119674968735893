.brand-lincoln {
    .vehicle-selector {
        .vehicle-selector-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 1rem;
            .dropdown-container-wrapper {
                padding-top: 0;
            }
        }

        .add-vehicle-container-tabs {
            margin-left: 4rem;
            .secondary {
                color: $primary-graphite;
                border: 0 transparent;
            }
        }

        .vehicle-tabs {
            padding-top: 20px;
        }
        .noVehiclesinGarage {
            pointer-events: none;
            background-color: $fds-color-disabled-light;
            .dropdown-button {
                .dropdown-current-item {
                    color: $fds-color-white;
                }
            }
        }
        .vehicle-tabs-dropdown {
            width: 210px;
            @include desktop {
                width: 380px;
                background-color: transparent;
            }
            .dropdown-button {
                background-color: #efefef;
                color: #000;
                border: none;
                border-bottom: 1px solid #000;
                border-radius: 0;
                font-family: $proxima-nova-regular;
                font-size: 16px;

                &:active {
                    background-color: transparent;
                }
            }

            .dropdown-items-panel {
                background-color: $fds-color-white;
                color: $primary-graphite;
                height: 155px;

                .dropdown-item {
                    &.selected-item {
                        color: $fds-color-black;
                        background: $fds-color-lt-gray;
                    }
                }
            }
        }

        .dropdown:hover .dropdown-button .vehicle-tabs-dropdown {
            background-color: $fds-color-white;
            color: $primary-graphite;
        }

        .tab-container .tab {
            margin: 0 40px 0 40px;
        }
    }
}
