.brand-lincoln {
    .vehicle-selector-fds {
        box-shadow: $outer-drop-shadow;
        display: inline-block;
        background-color: $white;
        border-radius: 3px;
        padding: 32px 12px;
        text-align: center;
        margin: 60px auto 0;
        @include tablet {
            padding: 32px 16px;
        }
        @include desktop {
            margin: 100px auto 0;
        }
        .input-container {
            display: flex;
            flex-direction: column;
            @include tablet {
                align-items: center;
            }
            @include desktop {
                flex-direction: row;
            }
            .ymm-vin-container {
                .input-field-container {
                    input {
                        max-width: 100%;
                        position: relative;
                        top: 7px;
                        left: -10px;
                        @include mobile {
                            top: 20px;
                        }
                    }
                    label.input-label {
                        top: -55px;
                        left: -10px;
                        font-size: 16px;
                        display: inline;
                        @include mobile {
                            top: -35px;
                        }
                    }
                    &:focus-within {
                        border-color: $brand-border;
                        border-width: 2px;
                        label.input-label {
                            top: -50px;
                            left: -10px;
                            font-size: 13px;
                            @include mobile {
                                top: -30px;
                            }
                        }
                    }
                }
            }
        }
        .or {
            padding: 1.625rem 0;
            min-width: 5rem;
            margin: auto 2rem;
            font-size: 14px;
        }

        .disclaimer {
            display: none;
            color: $brand-secondary;
            font-family: $f1-font-regular;
            font-size: 0.75rem;
            letter-spacing: 1px;
            line-height: 0.75rem;
            margin-top: 2rem;

            @include tablet {
                display: inline-block;
            }
        }

        .vehicle-selector-button {
            margin-top: 1.625rem;

            @include desktop {
                margin-top: 1.875rem;
            }
        }
        .fds-segmented-control__button-list-flex-container {
            margin-bottom: 30px;
            .fds-activity-indicator {
                margin: 6px 15px 0 15px;
                .fds-activity-indicator__progress-wrapper {
                    height: 0px;
                }
                .fds-activity-indicator__progress-wrapper:before {
                    height: 22px;
                    width: 22px;
                }
            }
        }

        .fds-segmented-control__panels {
            .fds-segmented-control__panel {
                &.fds-segmented-control__panel--show {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
        .vehicle-selector-fds-container {
            .fds-segmented-control {
                .fds-segmented-control__panels {
                    .fds-segmented-control__panel-content {
                        .input-container {
                            .ymm-vin-container {
                                .ymm-vin-body {
                                    .vin-input-container {
                                        .vin-input-field-container {
                                            letter-spacing: 0.058rem;
                                            .input-field-container {
                                                .input-field,
                                                .input-field-error {
                                                    top: 7px;

                                                    @include mobile {
                                                        top: 20px;
                                                    }
                                                }
                                                label.input-label {
                                                    top: -55px;
                                                    @include mobile {
                                                        top: -35px;
                                                    }
                                                }
                                                &:focus-within {
                                                    label.input-label {
                                                        top: -50px;
                                                        font-size: 13px;
                                                        @include mobile {
                                                            top: -30px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .support-fds-wrapper {
        .owner-manual-content {
            .selector-label {
                font-family: $f1-font-light;
                color: $brand-secondary;
                text-transform: uppercase;
                font-size: 1.125rem;
                letter-spacing: 3px;
                line-height: 1.875rem;
                font-weight: 300;
                margin: 0;
            }
        }
    }
}
