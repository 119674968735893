.brand-lincoln {
    .vha-alerts-container {
        .vha-alerts-accordion-container {
            padding: 1rem 0;
            @include tablet {
                padding: 1rem;
            }
            .vha-alert-headline {
                display: flex;
                &__icon {
                    margin-top: 4px;
                    max-width: 16px;
                }
                &__title {
                    text-align: left;
                    padding-left: 0.5rem;
                    color: $brand-primary;
                    font-family: $proxima-nova-light;
                    font-size: 16px;
                    letter-spacing: 1px;
                    line-height: 26px;
                }
            }
            .vha-alert-body {
                padding: 0 1rem 1rem;
                @include tablet {
                    padding: 0 2rem 1rem;
                }
                div {
                    padding: 0 0.5rem 0.5rem;
                    text-align: left;
                    letter-spacing: 1px;
                }
                .whatShouldIdo {
                    padding-top: 1rem;
                }
            }
        }
        .vha-alerts-cta {
            text-align: center;
            padding-bottom: 1rem;
            a {
                display: inline-flex;
                max-width: 222px;
            }
        }
        .notifications-divider {
            margin: 30px 0 20px;
            box-shadow: none;
        }
    }
}
