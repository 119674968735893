.brand-lincoln {
    .articles-links-container {
        max-width: 90rem;
        text-align: center;
        color: $text-gray;
        margin: 60px auto;
        @include desktop {
            margin: 100px auto;
        }
        .articles-links-title {
            margin: 20px 0 0 0;
            text-transform: uppercase;
            font-family: $proxima-nova-regular;
            font-size: 1.5rem;
            letter-spacing: 2px;
            text-align: center;
        }
        .articles-links {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: space-evenly;
            letter-spacing: 2px;
            margin-top: 60px;
            @include desktop {
                max-width: 90rem;
                flex-flow: row;
                padding: 0 36px;
            }
            .article-wrapper {
                display: flex;
                flex-direction: column;
                padding-left: 32px;

                @include desktop {
                    align-content: space-between;
                    flex-direction: row;
                }
            }
            .article-divider {
                border-bottom: 1px solid $border-grey;
                width: 296px;
                justify-content: center;
                align-self: center;
                @include desktop {
                    border-bottom-width: 0;
                    border-right: 1px solid $border-grey;
                    max-width: 36px;
                    align-self: auto;
                }
            }
        }
    }
}
