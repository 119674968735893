.brand-ford {
    .vehicle-selector {
        .vehicle-selector-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 1rem;
            .dropdown-container-wrapper {
                padding-top: 0;
            }
        }

        .add-vehicle-container-tabs {
            margin-left: 4rem;
        }

        .vehicle-tabs-dropdown-dark {
            width: 260px;
            @include desktop {
                width: 380px;
                background-color: transparent;
            }
            .dropdown-button {
                background-color: transparent;
                color: $dark-blue;
                padding: 0 16px;
                @include desktop {
                    padding: 0 16px;
                }
                @include tablet {
                    padding: 0 16px;
                }

                &:active {
                    background-color: transparent;
                }
            }

            .dropdown-items-panel {
                background-color: #ffffff;
                color: $dark-blue;
                height: 155px;

                .dropdown-item {
                    &.selected-item {
                        color: white;
                    }
                }
            }
        }
        .noVehiclesinGarage {
            pointer-events: none;
            background-color: $fds-color-disabled-light;
            .dropdown-button {
                .dropdown-current-item {
                    color: $fds-color-white;
                }
            }
        }
        .vehicle-tabs-dropdown {
            .vehicle-tabs-dropdown-dark,
            .dropdown-button {
                background-color: transparent;
                color: $white;
            }
        }
        .add-vehicle-container > button {
            &:hover {
                color: #1700f4;
                &::before {
                    border: 1px solid #1700f4;
                }
            }
        }
        .add-vehicle-container-light > button {
            &:hover {
                color: $white;
                &::before {
                    border: 1px solid $white;
                }
            }
        }
        .dropdown:hover .dropdown-button .vehicle-tabs-dropdown {
            background-color: #ffffff;
            color: $dark-blue;
        }

        .tab-container {
            .tab {
                margin: 0 40px 0 40px;
            }
        }
    }
}
