.brand-lincoln {
    .vehicle-tabs-nav-container {
        width: auto;
        height: 71px;
        display: flex;
        justify-content: space-between;
        background-color: $brand-secondary;
        .item1 {
            width: 100%;
            @include tablet {
                width: 432px;
            }
            height: 100%;
            border-right: 1px solid rgba(255, 255, 255, 0.5);
            display: flex;
            align-items: center;
            color: $white;
            .dropdown-container-wrapper {
                width: 100%;
                padding: 0;
                .dropdown-button {
                    border: none;
                    background: transparent;
                }
                .dropdown-current-item {
                    color: $white;
                    align-items: center;
                    padding-left: 8px;
                    font-size: 18px;
                    & > span {
                        @include tablet {
                            padding-left: 76px;
                        }
                        text-align: left;
                        & span:nth-child(2) {
                            font-size: pToR(13);
                            font-family: $proxima-nova-light;
                            line-height: 17px;
                            letter-spacing: 0px;
                        }
                    }
                }
                .dropdown-container {
                    .dropdown-items-panel {
                        margin-top: 13px;
                        z-index: 2000;
                        border: none;
                        @include tablet {
                            width: 432px;
                        }
                    }
                    .dropdown-item {
                        font-size: 18px;
                        padding-left: 24px;
                        height: 71px;
                        display: grid;
                        align-items: center;
                        letter-spacing: 0px;
                        padding-top: 13px;
                        color: $primary-graphite;
                        &.selected-item {
                            background-color: $white;
                            outline: 1px solid $primary-graphite;
                            outline-offset: -10px;
                        }
                        &:not(:last-child) {
                            border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
                        }

                        @include tablet {
                            padding-left: 100px;
                        }
                        &:hover {
                            background-color: $brand-border;
                            color: $white;
                            outline: unset;
                            .add-change-vehicle-button {
                                color: $white;
                            }
                        }
                        & span:nth-child(2) {
                            font-size: pToR(13);
                            line-height: 10px;
                        }
                        & .add-change-vehicle-button {
                            font-family: $proxima-nova-regular;
                            text-decoration: none;
                            background-color: transparent;
                            border: none;
                            cursor: pointer;
                            color: $brand-secondary;
                            font-size: 18px;
                            line-height: 24px;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            letter-spacing: 0px;
                            .fds-arrow {
                                -webkit-text-stroke-width: 1.3px;
                                font-size: 10px;
                            }
                            & fds-chevron.right {
                                display: inline-block;
                            }
                            &:hover {
                                color: $white;
                            }
                        }
                    }
                }
                .vehicle-option-label {
                    display: block;
                    line-height: 24px;
                    letter-spacing: 0px;
                    font-family: $proxima-nova-light;
                }
            }
            &:hover {
                background-color: $brand-border;
            }
        }
    }
    .vehicle-selector-menu {
        background-color: $primary-graphite;
        font-family: $proxima-nova-regular;
        font-weight: 400;
        .col-1 {
            .menu-button:hover,
            .menu-button:focus-visible {
                background-color: $secondary-accent-coral;
                color: $fds-color-white;
            }
            .menu-items-panel {
                .menu-item {
                    font-family: $proxima-nova-regular;
                    .selected-item {
                        background-color: $white;
                        outline: 1px solid $primary-graphite;
                        outline-offset: -10px;
                    }
                    .menu-item:hover {
                        font-weight: 400;
                        background-color: $secondary-accent-coral;
                    }
                }
            }
        }
    }
}
