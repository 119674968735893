.brand-lincoln {
    .vehicle-portal {
        .breadcrumbs.syndicate-header {
            @include mobile {
                padding-left: 1rem;
            }
        }
        .smart-tile-grid-wrapper.generic-grid,
        .smart-tile-grid-wrapper.dashboard {
            margin-top: 0px;
            @include desktop {
                margin-top: -30px;
            }
            .inner-smart-tile-grid {
                margin-top: unset;
            }
        }
        .banner {
            .top {
                @include desktop {
                    min-height: 21.8rem !important;
                    height: 350px;
                }
                .cta {
                    max-width: $max-content-width;
                    margin: 0 auto;
                    padding: 0 50px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    @include desktop {
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 0 100px;
                    }
                    .vehicle-image-na {
                        width: 390px;
                        margin-top: 30px;
                        @include desktop {
                            width: 527px;
                        }
                    }
                    .vehicle-image {
                        width: 390px;
                        margin-top: 30px;
                        @include desktop {
                            width: 527px;
                        }
                    }
                }
            }
        }
        .hero-tiles-container {
            top: -15px;
            @media (max-width: $max-tablet) {
                top: -96px;
            }
            @include mobile {
                top: -240px;
            }
            .tile-grid {
                grid-gap: 10px;
            }
        }
        .vehicle-portal-cta {
            color: $brand-secondary;
            padding-top: 63px;
            font-family: $proxima-nova-regular;
            @include desktop {
                text-align: left;
            }
            .vehicle-portal-header {
                font-weight: 400;
            }
            .vin {
                margin: 16px auto 24px;
                width: 100%;
                max-width: $max-content-width;
            }

            .greeting-header {
                color: $brand-secondary;
                font-size: 32px;
                letter-spacing: 0;
                line-height: 42px;
                z-index: 1;
                text-transform: capitalize;
            }

            .vehicle-portal-subhead {
                color: $brand-secondary;
                font-size: 18px;
                display: block;
                margin-top: 12px;
                letter-spacing: 0;
                line-height: 24px;
            }
            .vehicle-portal-desc {
                color: $brand-secondary;
                display: block;
                font-size: 16px;
                margin-top: 10px;
                letter-spacing: 0;
                line-height: 24px;
            }

            .second-signin-button {
                margin: 0;
                display: inline;
                .secondary-button-link {
                    padding: 0;
                    color: $brand-secondary;
                    text-decoration: underline;
                    text-underline-offset: 2px;
                    height: unset;
                    &:hover {
                        text-decoration: orangered;
                    }
                }
            }
        }
    }
}
